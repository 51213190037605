import { CustomContainer } from '@/components/Container/Container.styles'
import LocalThemeProvider from '@/components/LocalThemeProvider'
import WrapperContentItemsContainer from '../../components/WrapperContentItemsContainer'
import SecondaryTitle from '../../components/SecondaryTitle'
import PrimaryTitle from '../../components/PrimaryTitle'

import CustomerNavigationSlider from './CustomerNavigationSlider'
import { getSectionTheme } from '@/utils'
import { WrapperContentInterface } from '@/interfaces/wrapperContent'

const CustomerNavigationTab: React.FC<WrapperContentInterface> = (props) => {
  const {
    primaryTitle,
    secondaryTitle,
    theme,
    itemList,
    withSeparator
  } = props

  return (
    <LocalThemeProvider theme={{ sectionTheme: getSectionTheme(theme) }}>
      <WrapperContentItemsContainer withSeparator={withSeparator}>
        <CustomContainer>
          {secondaryTitle && <SecondaryTitle>{secondaryTitle}</SecondaryTitle>}
          <PrimaryTitle>{primaryTitle}</PrimaryTitle>
          <CustomerNavigationSlider itemList={itemList} />
        </CustomContainer>
      </WrapperContentItemsContainer>
    </LocalThemeProvider>
  )
}

export default CustomerNavigationTab
