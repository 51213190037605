import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

import { CustomerCardInterface } from '@/interfaces/CustomerCard'

import { CustomerCard } from '@/components'

// TODO: create common arrow component
import { Arrow } from '../../../LeadsWrapper/templates/LeadsPage/LeadsPage.styles'
import {
  SliderThumbnail,
  NextArrowContainer,
  PrevArrowContainer,
  CustomerNavigationSliderWrapper,
} from './CustomerNavigationSlider.styles'

interface CustomerNavigationSliderProps {
  itemList?: CustomerCardInterface[]
}

const CustomerNavigationSlider: React.FC<CustomerNavigationSliderProps> = ({ itemList: customerCardListData = [] }) => {
  const ANIMATION_SPEED = 1000

  const SETTINGS = {
    customPaging: function (slideIndex: number) {
      const customerLogo = customerCardListData[slideIndex]?.customerLogo
      return (
        <SliderThumbnail 
          animationSpeed={ANIMATION_SPEED}
        >
          <img src={customerLogo?.url} alt={customerLogo?.description} />
        </SliderThumbnail>
      )
    },
    fade: true,
    dots: true,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: ANIMATION_SPEED,
    dotsClass: 'slick-dots slick-thumb',
    variableWidth: false,
    nextArrow: (
      <NextArrowContainer>
        <Arrow />
      </NextArrowContainer>
    ),
    prevArrow: (
      <PrevArrowContainer>
        <Arrow />
      </PrevArrowContainer>
    ),
    responsive: [
      {
        breakpoint: 599,
        settings: {
          fade: false,
          swipeToSlide: true,
          dotsClass: 'slick-dots',
          centerMode: true,
          variableWidth: true,
          customPaging: (i:number) => <button>{i + 1}</button>
        }
      }
    ]
  }

  return (
    <CustomerNavigationSliderWrapper>
      <Slider {...SETTINGS}>
        {
          customerCardListData
            ?.filter((item: CustomerCardInterface) => item)
            ?.map((item: CustomerCardInterface) => {
              const {
                sys,
                imageAlignment,
                image,
                buttonText,
                buttonLink,
                customerName,
                customerLogo,
                customerImage,
                customerTitle,
                testimonialQuote,
                openInNewTab
              } = item
              return (
                <CustomerCard
                  key={sys?.id}
                  imageAlignment={imageAlignment}
                  image={image}
                  buttonText={buttonText}
                  buttonLink={buttonLink}
                  customerName={customerName}
                  customerLogo={customerLogo}
                  customerImage={customerImage}
                  customerTitle={customerTitle}
                  testimonialQuote={testimonialQuote}
                  openInNewTab={openInNewTab}
                />
              )})
        }
      </Slider>
    </CustomerNavigationSliderWrapper>
  )
}

export default CustomerNavigationSlider
